import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Suspense } from "react";
import {
  startConnection,
  stopConnection,
  invoke,
} from "../components/signalRService";
import Header from "../shared/Header";
import SettingsHeader from "../shared/SettingsHeader";
import SettingsSideBar from "../shared/SettingsSideBar";
import SuperAdminHeader from "../superAdmin/component/SuperAdminHeader";
import SuperAdminSidebar from "../superAdmin/component/SuperAdminSidebar";
import AdministrativeSettingSidebar from "../shared/AdministrativeSettingSidebar";

export const AuthorizedInner = ({
  cmp: Component,
  headerType = "CommonLayout",
  routeFor = "User",
  ...rest
}) => {
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [isSignUpCompleted, setIsSignUpCompleted] = useState(false);
  const [clientId, setClientId] = useState(localStorage.getItem("ClientID"));
  const [userId, setUserId] = useState(localStorage.getItem("UserID"));
  const [isDesktop, setDesktop] = useState(window.innerWidth >= 1024);
  const updateMedia = () => {
    setDesktop(window.innerWidth >= 1024);
  };

  const navigate = useNavigate();

  const checkAuthorization = () => {
    return localStorage.getItem("AccessToken") ? true : false;
  };

  const checkSuperAdminAuthorization = () => {
    return localStorage.getItem("SuperAdminToken") ? true : false;
  };



  useEffect(() => {
    if (headerType === "SuperAdminLayout") {
      const isAuthorize = checkSuperAdminAuthorization();
      setIsAuthorized(isAuthorize);
      if (!isAuthorize) {
        navigate("/superadmin/login");
      }
    } else {
      const isAuthorize = checkAuthorization();
      setIsAuthorized(isAuthorize);
      if (!isAuthorize) {
        navigate("/login");
      } else {
        const foundProfile = JSON.parse(
          localStorage.getItem("Profile") ?? "{}"
        );
        if (
          foundProfile.companyCreated === "No" &&
          foundProfile?.profileCompleted == "No"
        ) {
          navigate("/profileadmin");
          setIsSignUpCompleted(false);
        } else if (
          foundProfile?.profileCompleted == "No" &&
          foundProfile?.companyCreated == "Yes"
        ) {
          navigate("/profilemember");
          setIsSignUpCompleted(false);
        } else if (
          Number(localStorage.getItem("PORTALS_LENGTH")) > 1 &&
          !localStorage.getItem("selectedPortalIndex")
        ) {
          navigate("/portals");
        } else {
          setIsSignUpCompleted(true);
        }
      }
    }

    if (routeFor === "Admin") {
      const userType = localStorage.getItem("UserType");     
      if (userType !== "Admin") {
        navigate("/unauthorized");
      }
    }

    localStorage.setItem("IP_ADDRESS", "10.0.0.1");
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  }, []);

  useEffect(() => {
    if (headerType != "SuperAdminLayout") {
      if (!clientId && !userId && !isSignUpCompleted) return;

      setTimeout(() => {
        startConnection(clientId?.toString(), userId?.toString());
      }, 2000);

      return () => {
        stopConnection();
      };
    }
  }, [clientId, userId]);

  return (
    <>
      {
        isDesktop ? (
          <>
            {isAuthorized && (
              <>
                {headerType === "CommonLayout" && (
                  <>
                    <Header />
                    <div
                      style={{
                        background: "#FAFBFC",
                        minHeight: "100vh",
                        display: "grid",
                      }}
                    >
                      <div className="main-content">
                        <Suspense fallback={<div></div>}>
                          <Component {...rest} />
                        </Suspense>
                      </div>
                    </div>
                  </>
                )}
                {headerType === "SettingsLayout" && (
                  <div className="setting-module">
                    <SettingsHeader />
                    <div className="setting-flex">
                      <SettingsSideBar />
                      <Suspense fallback={<div></div>}>
                        <Component {...rest} />
                      </Suspense>
                    </div>
                  </div>
                )}
                {headerType === "AdministrativeSettingsLayout" && (
                  <div className="setting-module">
                    <SettingsHeader />
                    <div className="setting-flex">
                      <AdministrativeSettingSidebar />
                      <Suspense fallback={<div></div>}>
                        <Component {...rest} />
                      </Suspense>
                    </div>
                  </div>
                )}
                {headerType === "SuperAdminLayout" && (
                  <div className="setting-module">
                    <SuperAdminHeader />
                    <div className="setting-flex">
                      <SuperAdminSidebar />
                      <Suspense fallback={<div></div>}>
                        <Component {...rest} />
                      </Suspense>
                    </div>
                  </div>
                )}
              </>
            )}
          </>) : (
          <>
            <div>
              <div className="mobilelanding">
                <button className="closebtn">
                  <Link to="https://uniteam.ai/">
                    {" "}
                    <img alt="" src="images/mobile/close.svg" />
                  </Link>
                </button>
                <div className="mcontainer">
                  <Link to="https://uniteam.ai/" className="smlogo">
                    <img alt="image" src="images/icons-v2/uniteam-Logo-white-new.svg" width="150px" />
                  </Link>

                  {/* <span className="utlogo">
                <img alt="" src="images/mobile/uniteam.svg" />
              </span> */}
                  <div className="for-mob-link">
                    <p>
                      For best experience
                      <br />
                      download Uniteam
                      <br />
                      mobile aap
                    </p>

                    <div className="img-aap-store">
                      <a href="https://play.google.com/store/apps/details?id=com.opsedgeai.uniteam" target="_balnk">
                        <img alt="uniteamImage" src="/images/icons/googleplay.png" />
                      </a>
                    </div>
                    <div className="img-aap-store">
                      <a href="https://apps.apple.com/us/app/uniteam/id6479243058" target="_balnk">
                        <img alt="uniteamImage" src="/images/icons/appstore.png" />  </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
    </>
  );
};
